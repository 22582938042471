<template>
  	<!-- <div id="tax-year-modal" class="modal" aria-hidden="true"> -->
		<div class="modal-window_overlay tax-modal" tabindex="-1" id="tax-year-modal">
			<div @click.stop class="modal-window_container" role="dialog" aria-modal="true"
				aria-labelledby="aria-labelledby">
				<!-- <div  class="loader-cls"> -->
					<!-- <div class="flex loader-main"><loader /> <div class="loader-txt">Please Wait...</div></div>  -->
				<!-- </div> -->
				<div class="modal-content">
        <slot>
          <div class="refile-irs">
          <p class="font-bold">Select Tax Years</p>
          <div class="flex switch-cls">
									<InputSwitch class="input-switch" v-model="refileIRS" @click="toggleRefileIRS" />
									<label class="font-bold refile-text">Refile IRS</label>
					</div>
          </div>

          <div class="checkbox-custom">
          <input type="checkbox" id="select-all" v-model="selectAll" @change="toggleSelectAll">
          <label for="select-all"><span class="label-container">Select all tax years</span> </label>
          </div>
          <div v-for="year in years" :key="year" class="checkbox-custom">
            <input type="checkbox" :id="year" :value="year" v-model="selectedYears" :disabled="year==2022"> 
            <label :for="year">{{ year }}</label>
          </div>
          <div>
          <label for="description" class="desription">Reason*</label>
          <input type="text" id="description" v-model="description" width="90%" height="100px" :required="isDescriptionRequired" :disabled="!selectedYears.length" />
        </div>
          <div class="footer-buttons">
								<button class="button" @click="save" :disabled="!isSaveEnabled">OK</button>
								<button class="button secondary" type="button" @click="cancel">Cancel</button>
							</div>
        </slot>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </template>
  
  <script>

// import { watch } from "fs-extra";
// import micromodal from "micromodal";
import InputSwitch from 'primevue/inputswitch';
    export default {
      name: "TaxYearModal",
      components: { InputSwitch },
      props: {
		    driverTaxyears: {
			  type: Array,
			  required: true,
			default: () => [],
		    }
	    },
      data(){
        return { 
          selectedYears: [],
          description:"",
          selectAll:false,
          years:[],
          refileIRS: false
         };
      },
      computed:{
        isDescriptionRequired(){
          return this.selectedYears.length > 0;
        },
        isSaveEnabled(){
          return this.selectedYears.length > 0 && (!this.isDescriptionRequired || this.description.trim() !== '')
        },
      },
      mounted(){
        this.getTaxYears();
        this.selectedYears=[];
        this.description="";
      },
      methods:{
        getTaxYears(){
          let year = this.$props.driverTaxyears
          let ArrayLength = year.length
            // let year = new Date().getFullYear()-1;
            // for(let i = year;i > 2021; i-- ){
            //     this.years.push(i.toString())
            for(let i = 0;i < ArrayLength; i++ ){
            this.years.push(year[i].toString())
        }
        },
        toggleSelectAll() {
          if(this.selectAll){
            this.selectedYears= [...this.years]
          }
          else{
            this.selectedYears = []
          }
        },
        save(){
          this.$emit('save',{
            years:this.selectedYears,
            description:this.description,
            refileIRS:this.refileIRS
          });
        },
        cancel(){
          this.$emit('cancel');
        },
        toggleRefileIRS(){
			      this.refileIRS = !this.refileIRS;
		    },
      },
      watch: {
        selectAll(newVal){
          if(newVal){
            this.selectedYears= [...this.years];
          }
          else{
            this.selectedYears=[];
          }
        }
      }

    };
  </script>
  
  <style scoped>
    .option-cont{
        display: flex;
        align-items: center;
        margin: 15px 0;
    }
    .bg-margin{
      margin-top: 30px;
    }
    .status-drop-down{
        width: 200px;
        margin-left: 10px;
    }
    .modal-window_overlay {
	position: fixed;
	top: 100px;
	left: 0;
	right: 0;
	bottom: 0;
	background: #0000007a;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	overflow: hidden;
	z-index: 40;
}

.modal-window_container {
	background-color: #fff;
	padding: 24px;
	width: 40%;
	max-width: 400px;
	max-height: 60%;
	border-radius: 16px;
	overflow-y: auto;
	box-sizing: border-box;
	position: relative;
	font-family: 'Open Sans';
}
.footer-buttons {
	display: flex;
	gap: 24px;
	margin-top: 40px;
}
.desription{
  margin-bottom: 5px;
}
.font-bold {
  font-weight: bold;
}
.refile-irs{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
}
.switch-cls label{
	margin: 5px;
}
.refile-text{
  color: #333333;
}
  </style>